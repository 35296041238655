@media all and (min-width: 10px) {
    .Login {
      padding: 60px 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }


    .success {
      height: 10%;
      width: 50%;
      padding: 10px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }

    .header {
      padding: 10px;
      font-size: 30px;
      text-align: center;
      font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
  
    .fieldTitle {
      padding-top: 8px;
      margin: 1px;
      font-size: 18px;
      font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

    .Login form {
      margin: 0 auto;
      width: 320px;
    }

    .errorText {
      color: red;
      margin: 0;
    }
  }